import React, {useState, useEffect} from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import ServicesSection from './components/ServicesSection';
import Footer from './components/Footer';
import 'bulma/css/bulma.min.css';
import './styles/theme.css';
import HeroBottom from "./components/HeroBottom";
import MethodsSection from './components/MethodsSection';
import ContactsSection from './components/ContactsSection';

const App: React.FC = () => {
    const [theme, setTheme] = useState<'light' | 'dark'>('light');

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') as 'light' | 'dark';
        if (savedTheme) setTheme(savedTheme);
    }, []);

    const toggleTheme = () => {
        const newTheme = theme === 'light' ? 'dark' : 'light';
        setTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    return (
        <div data-theme={theme}>
            <Navbar toggleTheme={toggleTheme}/>
            <Hero/>
            <MethodsSection/>
            <ServicesSection/>
            <HeroBottom/>
            <ContactsSection />
            <Footer/>
        </div>
    );
};

export default App;
