import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';
const Hero: React.FC = () => {
    return (
        <section className="hero is-medium">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <h1 className="title" style={{marginBottom: '0.5rem'}}>Манитод</h1>
                    <h2 style={{ fontSize: '1rem', fontWeight: 'bold', color: 'var(--alt-text-color)', marginBottom: '1rem'}}> Ваш надежный партнер по переводу средств зарубеж.</h2>
                    <div className="buttons is-centered">
                        <a href="#contacts" className="button is-primary is-medium">Связаться с нами</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero;
