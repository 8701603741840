import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';

const MethodsSection: React.FC = () => {
    return (
        <section className="section">
            <div className="container">
                <div className="wk">
                    <h2 className="title has-text-centered">Как мы работаем</h2>
                    <p>Мы ведем постоянную разработку и улучшение методов перевода средств зарубеж, позволяющих обойти
                        санкции, обеспечивающих максимальную скорость и минимизацию издержек.</p>
                </div>
                <div>
                    <h3 className="title-2 has-text-centered">Услуги, которые выбирают наши клиенты</h3>
                </div>

                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i
                                className="fas fa-route"></i></span>Полный и косвенный реэкспорт</h3>
                            <p>При полном реэкспорте товары ввозятся в страну-резидента и затем вывозятся в страну импортера без уплаты пошлин и налогов. <br/> При косвенном реэкспорте товар отправляется напрямую в страну импортера, минуя границу страны-резидента.</p>

                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i
                                className="fas fa-yen-sign"></i></span>Валютный платёж в юанях</h3>
                            <p>Перевод валютного платежа без пересечения граци – деньги фактически не покидают границу
                                Российской Федерации, платёжи проходят внутри одного юрисдикционного пространства, но
                                между разными контрагентами.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MethodsSection;
