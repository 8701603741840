import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';
const ServicesSection: React.FC = () => {
    return (
        <section className="section">
            <div className="container">
                <div className="wk">
                    <h2 className="title has-text-centered">Почему клиенты выбирают нас</h2>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i className="fas fa-exchange-alt"></i></span>Обеспечение переводов зарубеж</h3>
                            <p>Мы предлагаем решения для перевода денежных средств из России в зарубежные юрисдикции.
                                Наши эксперты помогут вам выбрать наиболее подходящий способ перевода для вашего
                                бизнеса.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i className="fas fa-clock"></i></span>Сроки переводов</h3>
                            <p>Денежные средства поступают зарубежному контрагенту в срок от одного до пяти дней, в
                                зависимости от выбранной схемы платежа, что обеспечивает гибкость и оперативность.</p>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i className="fas fa-user-tie"></i></span>Непрерывный режим работы и персональный менеджер</h3>
                            <p>Каждому клиенту назначается персональный менеджер, который сопровождает сделку на всех
                                этапах, обеспечивая быстрое и комфортное взаимодействие.</p>
                        </div>
                    </div>
                    <div className="column">
                        <div className="box">
                            <h3 className="title is-4"><span className="icon"><i className="fas fa-project-diagram"></i></span>Выверенные
                                и отработанные способы переводов по сложным товарным позициям</h3>
                            <p>Мы предлагаем различные способы перевода средств, учитывая особенности товарных отношений
                                между российскими заказчиками и зарубежными поставщиками.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesSection;
