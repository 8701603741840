import React, { useState } from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';
import { sendContactForm, ContactFormData } from '../api/contactApi';

const ContactsSection: React.FC = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [fio, setFio] = useState('');

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(e.target.value);
    };

    const handleFioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFio(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!phoneNumber) {
            alert('Пожалуйста, введите номер телефона.');
            return;
        }

        const formData: ContactFormData = {
            phoneNumber,
            fio: fio || '',
            email: '',
            comment: '',
        };

        const isSuccess = await sendContactForm(formData);

        if (isSuccess) {
            alert('Заявка успешно отправлена!');
            setPhoneNumber('');
            setFio('');
        } else {
            alert('Ошибка при отправке.');
        }
    };

    return (
        <section className="section" id="contacts">
            <div className="container">
                <div className="columns is-centered">
                    {/* Секция "Наши контакты" */}
                    <div className="column is-half">
                            <h2 className="title has-text-left">Наши контакты</h2>
                            <ul className="contacts">
                                <li>
                                    <h3 className="title is-6">Телефон</h3>
                                    <a href="tel:+78129992233" style={{color: 'var(--text-color)'}}>+7 (812) 999-22-33</a>
                                </li>
                                <li>
                                    <h3 className="title is-6">Email</h3>
                                    <p>Mtoad.spb@yandex.ru</p>
                                </li>
                                <li>
                                    <h3 className="title is-6">Адрес</h3>
                                    <p>г.Санкт-Петербург</p>
                                </li>
                            </ul>
                    </div>

                    <div className="column is-half">
                            <h2 className="title has-text-left">Оставьте заявку на обратный звонок</h2>
                            <form onSubmit={handleSubmit}>
                                <div className="field">
                                    <h3 className="title is-6">Телефон</h3>
                                    <input
                                        type="tel"
                                        placeholder="+7 (___) ___-__-__"
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        required
                                        className="input"
                                        style={{ width: '250px' }}
                                    />
                                </div>
                                <div className="field">
                                    <h3 className="title is-6">ФИО</h3>
                                    <input
                                        type="text"
                                        placeholder="Введите ваше ФИО"
                                        value={fio}
                                        onChange={handleFioChange}
                                        className="input"
                                        style={{ width: '250px' }}
                                    />
                                </div>
                                <button
                                 type="submit" className="button is-primary is-small" style={{ padding: '8px'}}>
                                    Отправить
                                </button>
                            </form>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactsSection;
