import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';
const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="content has-text-centered">
                <p><strong style={{color : 'var(--title-color)'}}>Манитод</strong> © 2024. Все права защищены.</p>
                <p><a href="#contacts">Политика конфиденциальности</a> | <a href="#contacts">Контакты</a></p>
            </div>
        </footer>
    );
};

export default Footer;
