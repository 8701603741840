import React from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';

const HeroBottom: React.FC = () => {
    return (
        <section className="section inverted">
            <div className="container has-text-centered">
                <h1 className="title">Наша миссия</h1>

                <p>Международные платежи из России в Китай в 2024 году сталкиваются с серьёзными трудностями из-за санкций США и страха китайских банков перед вторичными санкциями. Большинство крупных и региональных банков Китая перестали принимать переводы от российских компаний, в результате чего российский бизнес испытывает проблемы как возврат платежей, замораживание средств и потеря финансов на конвертации валюты.</p>
                <p>С декабря 2023 года многие крупные китайские банки, такие как ICBC и China Construction Bank, прекратили принимать платежи из России, даже в юанях. В 2024 году эта тенденция усилилась, в результате около 98% китайских банков не работают с платежами из России.</p>
                <p>Переводы часто возвращаются обратно в Россию, что приводит к значительным потерям времени и средств на конвертацию валют. Из-за этого российские компании вынуждены искать обходные схемы, такие как переводы через третьи страны или использование криптовалюты, хотя эти методы не всегда работают и могут нести высокие риски​. Компания «Манитод» готова взять на себя обязательства по переводу в короткие сроки Ваших средств за рубеж на счета Ваших поставщиков. Мы готовы обеспечить чистоту  операции без рисков обнаружения российского следа.</p>
            </div>
        </section>
    );
};

export default HeroBottom;
