import React, {useEffect, useState} from 'react';
import 'bulma/css/bulma.min.css';
import '../styles/theme.css';

interface NavbarProps {
    toggleTheme: () => void;
}

const Navbar: React.FC<NavbarProps> = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 933);

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme');
        if (savedTheme) {
            document.body.dataset.theme = savedTheme;
        } else {
            const systemTheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
            document.body.dataset.theme = systemTheme;
            localStorage.setItem('theme', systemTheme);
        }

        const handleResize = () => setIsMobile(window.innerWidth < 933);
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleMenuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="container">
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <span className="icon"><i className="fas fa-frog"></i></span>
                        <strong>Манитод</strong>
                    </a>
                    {isMobile && (
                        <button
                            className={`navbar-burger ${isMenuOpen ? 'is-active' : ''}`}
                            onClick={handleMenuToggle}
                            aria-label="menu" aria-expanded="false"
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    )}
                </div>
                <div className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
                    <div className="navbar-end">
                        <a href="#contacts" className="navbar-item">Контакты</a>
                        <a href="tel:+78129992233" className="navbar-item">+7 (812) 999-22-33</a>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
